#translateContainer {
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: white;

  #translate {
    height: calc(100vh - 100px);
    background-color: white;
  }

  #inputButton {
    background-color: white;
    padding: 1%;
    display: flex;
    justify-content: center;

    #fileInput {
      border: solid 1px #b5b5b5;
      padding: 10px;
      width: 20%;
      border-radius: 1rem;
    }

    #langTarget {
      margin-left: 20px;
      border-radius: 1rem;
    }

    #workButton {
      margin-left: 20px;
      background-image: linear-gradient(246deg, #ffaa16 100%, #e56300);
      font-family: NotoSans;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: white;
      border: none;

      padding: 10px 20px 10px 20px;
      border-radius: 5px;
      cursor: pointer;
    }
    #resetButton {
      margin-left: 20px;
      background-color: #4a5988;
      font-family: NotoSans;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: white;
      border: none;

      padding: 10px 20px 10px 20px;
      border-radius: 5px;
      cursor: pointer;
    }

    #updateButton {
      display: none;
      margin-left: 20px;
      background-image: linear-gradient(246deg, #ffaa16 100%, #e56300);
      font-family: NotoSans;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: white;
      border: none;

      padding: 10px 20px 10px 20px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  #transBot {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    #left {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      #arrowRight {
        font-size: 30px;
        margin: 10px;
        width: 200px;
      }

      .imgContainer {
        width: 45%;
        overflow-y: auto;
        position: relative;
        border: 1px black solid;
        margin: 10px;

        #original {
          width: 100%;
        }

        #blank {
          display: flex;
          position: relative;
        }

        #empty {
          width: 100%;
        }
      }
    }

    #right {
      width: 50%;
      padding: 10px;
      margin: 10px;
      border: 1px black solid;

      .rightContainer {
        padding: 20px;
        border-bottom: 1px orange dashed;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
        position: sticky;
        font-family: "Noto Sans KR", sans-serif;

        #googleLabel {
          color: #2b7de9;
          margin-top: 5px;
        }

        #papagoLabel {
          color: #21dc6d;
          margin-top: 5px;
        }

        .inputBox {
          height: 50px;
          resize: none;
          margin-top: 10px;
          font-family: "Noto Sans KR", sans-serif;
        }
      }

      #buttonDiv {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        #gooButton {
          background: #2b7de9;
          color: #fff;
          border-radius: 3px;
          box-sizing: border-box;
          display: block;
          font-size: 13px;
          height: 33px;
          line-height: 31px;
          margin-right: 10px;
          padding: 0 15px;
          text-align: center;
          width: 100px;
          border: none;
          cursor: pointer;
        }

        #papButton {
          background: #21dc6d;
          color: #fff;
          border-radius: 3px;
          box-sizing: border-box;
          display: block;
          font-size: 13px;
          height: 33px;
          line-height: 31px;
          padding: 0 15px;
          text-align: center;
          width: 100px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #translateContainer {
    height: fit-content;
    #translate {
      height: fit-content;
    }
    #inputButton {
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      #fileInput {
        width: 75%;
      }
      #langTarget {
        width: 80%;
        padding: 10px;
        margin-left: 0px;
        margin-top: 10px;
      }
      #workButton {
        width: 80%;
        margin: 10px 0 0 0;
        background-image: linear-gradient(263deg, #ffaa16, #e56300);
      }
      #resetButton {
        width: 80%;
        margin: 10px 0 0 0;
      }
      #updateButton {
        display: block;
        width: 80%;
        margin: 10px 0 0 0;
      }
    }

    #transBot {
      margin-top: 20px;
      margin-bottom: 20px;
      #left {
        flex-direction: column;
        width: 100%;
        align-items: center;
        .imgContainer {
          flex-direction: column;
          width: 80%;
          height: 50vh;

          #imgDiv {
            width: 80%;
            max-width: none;
            height: 50vh;
          }
        }
        #blank {
          font-size: 10px;
        }
        #arrowRight {
          transform: rotate(90deg);
        }
      }

      #right {
        display: none;
        flex-direction: column;
        width: 80%;

        #buttonDiv {
          justify-content: space-around;
          #gooButton {
            width: 45%;
          }
          #papButton {
            width: 45%;
          }
        }
      }
    }
  }
}
