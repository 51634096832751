img._white {
  width: 106px;
  height: 26px;
  object-fit: contain;
}

#basicContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 65%;
}

#top {
  font-family: NotoSans;
  font-size: 28px;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

#buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;

  .buttons {
    width: 450px;
    height: 300px;
    margin: 0 20px 21px;
    padding: 85px 46.5px 64.3px 57.5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-family: NotoSans;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .buttons:hover {
    background-color: #f69833;
  }
}

#line {
  width: 85%;
  height: 2px;
  background-color: #fff;
}

#-AI {
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  word-break: keep-all;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .App {
    background-image: linear-gradient(194deg, #4e5e8f, #1d2237);
    justify-content: unset;
    overflow-y: auto;
  }
  #basicContainer {
    // display: flex;
    flex-direction: column;
    z-index: 2;
    width: 80%;
  }
  img._white {
    width: 106px;
    height: 26px;
    object-fit: contain;
  }
  #top {
    margin-top: 10%;
    font-family: NotoSans;
    font-size: 18px;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: #fff;
  }

  #buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 30px;
    .buttons {
      width: 100%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.28);
      border: solid 1px #fff;
      padding: 10%;
      margin: 20px 0 0 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .icon {
        width: 30px;
        height: 30px;
      }
    }

    .buttons:hover {
      background-color: #f69833;
    }
  }
}
