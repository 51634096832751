#login {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

#loginContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.IDEACONCERT-Technology-Demo-Page {
  width: 844px;
  height: 184px;
  margin: 0 0 70px;
  font-family: NotoSans;
  font-size: 70px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.loginInput {
  width: 260px;
  height: 33px;
  margin: 20px;
  padding: 18px 28px 17px 16px;
  border-radius: 5px;
  border: solid 1px #1e2339;
  background-color: #fff;
  font-size: 22px;
}

.loginInput::placeholder {
  font-size: 22px;
}

#loginButton {
  width: 70%;
  height: 68px;
  margin: 30px 0 0;
  padding: 18px 184px 17px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-image: linear-gradient(264deg, #ffaa16, #e56300);
  color: #fff;
  font-family: NotoSans;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

@media only screen and (max-width: 768px) {
  #login {
    height: 100%;
    justify-content: center;
  }
  .IDEACONCERT-Technology-Demo-Page {
    width: 353px;
    height: 81px;
    margin: 0 0 50px;
    font-family: NotoSans;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  #loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .loginInput {
    width: 260px;
    height: 33px;
    margin: 20px;
    padding: 18px 28px 17px 16px;
    border-radius: 5px;
    border: solid 1px #1e2339;
    background-color: #fff;
    font-size: 22px;
  }

  .loginInput::placeholder {
    font-size: 22px;
  }

  #loginButton {
    width: 334px;
    height: 50px;
    border-radius: 5px;
    background-image: linear-gradient(261deg, #ffaa16, #e56300 0%);
    color: #fff;
    font-size: 16px;
    padding: 0 0 0 0;
  }
}
