.App {
  text-align: center;
  background-image: linear-gradient(241deg, #4e5e8f, #1d2237);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  img.back {
    width: 1150px;
    height: 736px;
    padding: 174px 156px 142px 150px;
    object-fit: contain;
    position: absolute;
    z-index: 0;
  }
}

@media only screen and (max-width: 768px) {
  .App {
    background-image: linear-gradient(241deg, #4e5e8f, #1d2237);
    img.back {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 0 0 0 0;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
