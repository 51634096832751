#resolutionLoadingDiv {
  position: fixed;
  width: 25%;
  height: 25%;
  transform: translate(150%, 150%);
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 1rem;

  #bigCircle {
    width: 250px;
    height: 250px;
    background-image: linear-gradient(to bottom, #1d2237, #424f79);
    border-radius: 100%;
  }

  #box {
    position: absolute;
    width: 200px;
    height: 200px;
  }
  .box2 {
    transform: rotate(35deg);
  }
  .box3 {
    transform: rotate(70deg);
  }
  .box4 {
    transform: rotate(105deg);
  }
  .box5 {
    transform: rotate(140deg);
  }
  .circle2 {
    transform: scale(0.8);
  }
  .circle3 {
    transform: scale(0.6);
  }
  .circle4 {
    transform: scale(0.4);
  }
  .circle5 {
    transform: scale(0.2);
  }
  #circle {
    position: relative;
    top: 0px;
    left: 50px;
    border-radius: 50%;
    background-color: #ff9f3e;
    animation: rotr 4s linear infinite;
    height: 20px;
    width: 20px;
  }
  #all {
    position: absolute;
    width: 200px;
    height: 200px;

    animation: myfirst;
    animation-duration: 05s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    animation-name: myfirst;
    animation-duration: 05s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes myfirst {
    0% {
      transform: rotate(360deg);
    }
  }
  @keyframes myfirst /* Safari and Chrome */ {
    0% {
      transform: rotate(360deg);
    }
  }

  #smallCircle {
    position: absolute;
    background-image: linear-gradient(133deg, #c5c5c5 15%, #f1f1f1 86%);
    width: 150px;
    height: 150px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: focus-in infinite alternate 2s
      cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  @keyframes focus-in {
    0% {
      filter: blur(12px);
      opacity: 1;
    }
    75% {
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 768px) {
  #resolutionLoadingDiv {
    width: 250px;
    transform: translate(25%, 150%);
  }
}
