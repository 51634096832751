#resolutionContainer {
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: white;

  #inputButton {
    background-color: white;
    padding: 1%;
    display: flex;
    justify-content: center;
    #fileInput {
      border: solid 1px #b5b5b5;
      padding: 10px;
      width: 20%;
    }
    #workButton {
      margin-left: 20px;
      background-image: linear-gradient(246deg, #ffaa16 100%, #e56300);
      font-family: NotoSans;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: white;
      border: none;

      padding: 10px 20px 10px 20px;
      border-radius: 5px;
      cursor: pointer;
    }
    #resetButton {
      margin-left: 20px;
      background-color: #4a5988;
      font-family: NotoSans;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: white;
      border: none;

      padding: 10px 20px 10px 20px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  #imgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    #workedDiv {
      width: 100px;
      overflow: auto;
      border: 1px black solid;
      min-width: 40%;
      max-width: 50%;
      height: 800px;
      overflow: auto;
      position: relative;
      cursor: grab;
      display: block;
    }

    #imgDiv {
      width: 100px;
      min-width: 40%;
      max-width: 50%;
      height: 800px;
      overflow: auto;
      border: 1px black solid;
      display: flex;
      flex-direction: column;
    }

    #arrowRight {
      font-size: 30px;
      margin: 10px;
      width: 200px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #resolutionContainer {
    height: fit-content;
    #inputButton {
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      #fileInput {
        width: 80%;
      }
      #workButton {
        width: 80%;
        margin: 10px 0 0 0;
        background-image: linear-gradient(263deg, #ffaa16, #e56300);
      }
      #resetButton {
        width: 80%;
        margin: 10px 0 0 0;
      }
    }

    #imgContainer {
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 20px;
      #imgDiv {
        width: 80%;
        max-width: none;
        height: 50vh;
      }
      #workedDiv {
        width: 80%;
        max-width: none;
        height: 50vh;
      }
      #arrowRight {
        transform: rotate(90deg);
      }
    }
  }
}
