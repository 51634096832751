#topperDiv {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  background-image: linear-gradient(267deg, #4e5e8f 100%, #1d2237);
}

#topperLogo {
  margin-left: 1%;
  height: 75%;
}

#topperText {
  margin-left: 1%;
  font-size: 38px;
  font-family: NotoSans;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: white;
}

#back {
  color: white;
  font-size: 22px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #topperDiv {
    // height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    background-image: linear-gradient(255deg, #4e5e8f, #1d2237);
  }

  #topperLogo {
    margin-left: 1%;
    height: 75%;
  }

  #topperText {
    margin-left: 1%;
    font-size: 18px;
    font-family: NotoSans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: white;
  }

  #back {
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
}
